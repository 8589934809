import getConfig from 'next/config';
import { Button, ButtonProps } from '@mui/material';
import { FC } from 'react';
import { AccountStatus } from '@/API/member';
import { getEnrollmentUrl } from '@/utils/urlHelper';
import { ENROLLMENT_IN_PROGRESS_URI, PARTNER_ENROLLMENT_IN_PROGRESS_URI } from '@/constants';
import { NextConfig } from '@/interfaces';
import { useUserAuth } from '@/components/UserProvider';
import { logAnalyticsEvent, AmplitudeEventName, PageProperties } from '@/utils/AnalyticsHelper';
import { getPartnersRXCookie } from '@/utils/helpers';

const { publicRuntimeConfig } = getConfig() as NextConfig;
const { BREEDLOVE_LOGIN_URL, CORE_BASE_URL } = publicRuntimeConfig;

interface Props extends ButtonProps {
  className?: string;
  ctaLocation?: string;
  pageProperties: PageProperties;
  partnerName?: string;
  marketingPath?: string;
}

const CTAButton: FC<Props> = ({
  ctaLocation,
  pageProperties,
  partnerName,
  marketingPath,
  ...props
}) => {
  const { auth } = useUserAuth();
  const partnersRx = getPartnersRXCookie();

  const enrollmentUrl = partnerName
    ? PARTNER_ENROLLMENT_IN_PROGRESS_URI
    : ENROLLMENT_IN_PROGRESS_URI;

  const buttonState = {
    [AccountStatus.Pending]: {
      text: 'Continue registration',
      href: `${CORE_BASE_URL}${enrollmentUrl}`,
    },
    [AccountStatus.Submitted]: {
      text: 'Approval pending',
      href: `${CORE_BASE_URL}${enrollmentUrl}`,
    },
    [AccountStatus.Client]: { text: 'My account', href: BREEDLOVE_LOGIN_URL },
    [AccountStatus.None]: {
      text: 'Get started',
      href: getEnrollmentUrl(undefined, partnersRx, marketingPath),
    },
    undefined: {
      text: 'Get started',
      href: getEnrollmentUrl(undefined, partnersRx, marketingPath),
    },
  };

  const handleCtaClick = (ctaText: string) => {
    logAnalyticsEvent({
      data: {
        cta_location: ctaLocation,
        cta_text: ctaText,
      },
      name: AmplitudeEventName.ctaInteracted,
      pageProperties: {
        ...(pageProperties as PageProperties),
        partnerName,
        marketingPath,
      },
    });
  };

  return (
    <Button
      href={buttonState[auth?.member?.homepayAccountStatusV2 as AccountStatus].href}
      onClick={() =>
        handleCtaClick(buttonState[auth?.member?.homepayAccountStatusV2 as AccountStatus].text)
      }
      {...props}>
      {buttonState[auth?.member?.homepayAccountStatusV2 as AccountStatus].text}
    </Button>
  );
};

export default CTAButton;
