import { Grid } from '@mui/material';
import { theme } from '@care/material-ui-theme';
import { FC } from 'react';
import ProportalBulletTile from './ProportalBulletTile';

interface IProportalTiles {
  hideTileDescriptionMarginTop?: boolean;
}

const features = [
  {
    description:
      'Let our HomePay service handle all the forms, filings and tax agency correspondence.',
    title: 'Complete Compliance – Guaranteed',
    key: 1,
  },
  {
    description: 'Access organized tax records and generate reports from your partner dashboard.',
    title: 'Full Visibility',
    key: 2,
  },
  {
    description: 'Simplify payroll management with options designed for household employment.',
    title: 'Streamlined Payroll Management',
    key: 3,
  },
  {
    description:
      'Our collaborative online registration makes getting clients set up quick and easy.',
    title: 'Seamless Onboarding',
    key: 4,
  },
];

const ProportalTiles: FC<IProportalTiles> = ({ hideTileDescriptionMarginTop }) => {
  return (
    <Grid
      container
      direction="column"
      data-testid="tiles-container"
      paddingLeft={theme.spacing(6)}
      sx={{
        paddingLeft: {
          xs: theme.spacing(3),
          md: theme.spacing(6),
        },
        paddingRight: theme.spacing(3),
      }}>
      {features.map((feature) => (
        <Grid item key={feature.key}>
          <ProportalBulletTile
            title={feature.title}
            description={feature.description}
            hideTileDescriptionMarginTop={hideTileDescriptionMarginTop}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ProportalTiles;
