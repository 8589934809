import { Box } from '@mui/material';
import { FC } from 'react';
import { theme } from '@care/material-ui-theme';
import { AccountStatus } from '@/API/member';
import { useUserAuth } from '@/components/UserProvider';
import { PageProperties } from '@/utils/AnalyticsHelper';
import TrustBuildingFlowCard from './TrustBuildingFlowCard';
import CTAButton from '../CTAButton/CTAButton';

interface Props {
  ctaLocation: string;
  pageProperties: PageProperties;
}

const EnrollmentCTA: FC<Props> = ({ ctaLocation, pageProperties }) => {
  const { auth } = useUserAuth();

  return (
    <>
      {!auth?.member?.homepayAccountStatusV2 ||
      auth.member?.homepayAccountStatusV2 === AccountStatus.None ? (
        <Box
          sx={{
            marginBottom: theme.spacing(1.5),
            justifyContent: 'center',
            display: 'flex',
            [theme.breakpoints.up('md')]: {
              margin: theme.spacing(0, 0, 4, 0),
              display: 'inline-block',
              justifyContent: 'left',
            },
          }}>
          <TrustBuildingFlowCard ctaLocation={ctaLocation} pageProperties={pageProperties} />
        </Box>
      ) : (
        <Box
          sx={{
            margin: theme.spacing(0, 3, 3, 3),
            [theme.breakpoints.up('sm')]: {
              margin: theme.spacing(7, 2),
            },
            [theme.breakpoints.down('md')]: {
              textAlign: 'center',
            },
          }}>
          <CTAButton
            sx={{
              maxWidth: '450px',
              textAlign: 'center',
              width: '100%',
            }}
            color="primary"
            ctaLocation={ctaLocation}
            pageProperties={pageProperties}
            size="medium"
            variant="contained"
          />
        </Box>
      )}
    </>
  );
};

export default EnrollmentCTA;
